/// <reference types="@types/gapi" />
/// <reference types="@types/google.picker" />

import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleApisService {
  private clientInitialized = false;
  private pickerInitialized = false;

  protected baseUrl: string;

  async initClient() {
    if (this.clientInitialized) {
      return;
    }

    await gapi.client.init({
      apiKey: environment.firebase.apiKey,
    });

    this.clientInitialized = true;
  }

  async initPicker() {
    await this.initClient();

    if (this.pickerInitialized) {
      return;
    }

    return new Promise<void>((resolve) => {
      gapi.load('picker', () => {
        this.pickerInitialized = true;

        resolve(undefined);
      });
    });
  }

  async showPicker(oauthToken: string) {
    await this.initPicker();

    return new Promise<void>((resolve) => {
      var docsView = new google.picker.DocsView()
        .setIncludeFolders(true)
        .setMimeTypes(
          'application/vnd.google-apps.folder,application/vnd.google-apps.document,application/vnd.google-apps.presentation,text/plain',
        )
        .setSelectFolderEnabled(false);

      const picker = new google.picker.PickerBuilder()
        .addView(docsView)
        .setOAuthToken(oauthToken)
        .setDeveloperKey(environment.firebase.apiKey)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
        .setAppId(environment.googleClientId)
        .setCallback((data: any) => {
          if (data.action === google.picker.Action.PICKED) {
            const doc = data.docs[0];

            resolve(undefined);
          } else if (data.action === google.picker.Action.CANCEL) {
            resolve(undefined);
          }
        })
        .build();

      picker.setVisible(true);
    });
  }
}
